import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./map2.module.scss"
import ScrollContainer from "react-indiana-drag-scroll"

import MapNavigation from "../../components/mapNavigation/mapNavigation"

//import Image from "../../components/image"
import SVGMap from "../../assets/JWC_MAP_04.svg"
//import MapElementLink from "../mapElementLink/mapElementLink"
//import MapElementLinkImage01 from "../../images/JWC_item-01_00.png"
import MapElementLinkImage01 from "./mapElementLinkImage01.js"
import MapElementLinkImage02 from "./mapElementLinkImage02.js"
import MapElementLinkImage03 from "./mapElementLinkImage03.js"
import MapElementLinkImage04 from "./mapElementLinkImage04.js"
import MapElementLinkImage05 from "./mapElementLinkImage05.js"
import MapElementGif01 from "../../images/JWC_item-08_00.gif"
import MapElementGif02 from "../../images/JWC_item-09_00.gif"
import MapElementGif03 from "../../images/JWC_item-10_00.gif"

function Map2() {
  const container = useRef(null)
  const mapContainer = useRef(null)

  const mapElementShop = useRef(null)
  const mapElementVision = useRef(null)
  const mapElementJiwon = useRef(null)
  const mapElementCollections = useRef(null)
  const mapElementNews = useRef(null)
  //const mapElementShop = useRef(null)

  function executeScroll(elementRef) {
    const containerHeight = container.current.getElement().clientHeight
    const containerWidth = container.current.getElement().clientWidth

    const elementRefTop = elementRef.current.offsetTop
    const elementRefLeft = elementRef.current.offsetLeft
    //const elementRefHeight = elementRef.current.clientHeight
    //const elementRefWidth = elementRef.current.clientWidth

    return (
      // Debug
      /*
            console.log('container Height: ' + containerHeight),
            console.log('container Width: ' + containerWidth),
            
            console.log('element Top: ' + elementRefTop),
            console.log('element Left: ' + elementRefLeft),
            console.log('element Height: ' + elementRefHeight),
            console.log('element Width: ' + elementRefWidth),
            */
      container.current.getElement().scrollTo({
        top: elementRefTop - containerHeight / 2,
        left: elementRefLeft - containerWidth / 2,
        behavior: "smooth",
      })
    )
  }

  function mapZoomIn() {
    //alert('zoom-in')
    //alert(container.current.getElement().clientWidth)
    container.current.getElement().style.width = "150%"
    container.current.getElement().style.overflow = ""
  }
  function mapZoomOut() {
    //alert('zoom-out')
    //alert(container.current.getElement().clientWidth)
    //alert(window.innerWidth)
    container.current.getElement().style.width = "100%"
    container.current.getElement().style.overflow = ""
  }

  useEffect(() => {
    container.current.getElement().scrollTo({
      top:
        mapElementShop.current.offsetTop -
        container.current.getElement().clientHeight / 2,
      left:
        mapElementShop.current.offsetLeft -
        container.current.getElement().clientWidth / 2,
      behavior: "smooth",
    })
  }, [])

  return (
    <>
      <ScrollContainer
        className={styles.scrollContainer}
        ref={container}
        hideScrollbars={true}
      >
        <div className={styles.mapContainer} ref={mapContainer}>
          {/*<img id={styles.mapElementGif01} src={MapElementGif01} alt="" />*/}
          {/*<img id={styles.mapElementGif02} src={MapElementGif02} alt="" />*/}
          {/*<img id={styles.mapElementGif03} src={MapElementGif03} alt="" />*/}

          <SVGMap />

          <div
            id={"mapElementShop"}
            ref={mapElementShop}
            className={"mapElement"}
          >
            <Link to={"/shop/"}>
              {/*<img src={MapElementLinkImage01} */}
              <MapElementLinkImage05></MapElementLinkImage05>
              <h2 className={"mapElementTitle"}>Shop</h2>
            </Link>
          </div>

          <div
            id={"mapElementVision"}
            ref={mapElementVision}
            className={"mapElement"}
          >
            <Link to={"/vision/"}>
              {/*<img src={MapElementLinkImage01} */}
              <MapElementLinkImage01></MapElementLinkImage01>
              <h2 className={"mapElementTitle"}>Vision</h2>
            </Link>
          </div>

          <div
            id={"mapElementJiwon"}
            ref={mapElementJiwon}
            className={"mapElement"}
          >
            <Link to={"/jiwon/"}>
              <MapElementLinkImage02></MapElementLinkImage02>
              <h2 className={"mapElementTitle"}>Ji Won</h2>
            </Link>
          </div>

          <div
            id={"mapElementCollections"}
            ref={mapElementCollections}
            className={"mapElement"}
          >
            <Link to={"/collections/"}>
              <MapElementLinkImage03></MapElementLinkImage03>
              <h2 className={"mapElementTitle"}>Collections</h2>
            </Link>
          </div>

          <div
            id={"mapElementNews"}
            ref={mapElementNews}
            className={"mapElement"}
          >
            <Link to={"/news/"}>
              <MapElementLinkImage04></MapElementLinkImage04>
              <h2 className={"mapElementTitle"}>News</h2>
            </Link>
          </div>

          <div className={"mapNavigation"}>
            <div
              className="square bg_green"
              onClick={() => executeScroll(mapElementShop)}
            >
              <div className="label">
                <span>Shop</span>
              </div>
            </div>
            <div
              className="square bg_orange"
              onClick={() => executeScroll(mapElementVision)}
            >
              <div className="label">
                <span>Vision</span>
              </div>
            </div>
            <div
              className="square bg_pink"
              onClick={() => executeScroll(mapElementJiwon)}
            >
              <div className="label">
                <span>Ji Won</span>
              </div>
            </div>
            <div
              className="square bg_purple"
              onClick={() => executeScroll(mapElementCollections)}
            >
              <div className="label">
                <span>Collections</span>
              </div>
            </div>
            <div
              className="square bg_blue"
              onClick={() => executeScroll(mapElementNews)}
            >
              <div className="label">
                <span>News</span>
              </div>
            </div>
            {/* <Link to="/shop/" className='square bg_green'></Link> */}
          </div>
          <div className={"mapZoom"}>
            <div className={"mapZoom_in square"} onClick={() => mapZoomIn()}>
              <span></span>
              <span></span>
            </div>
            <div className={"mapZoom_out square"} onClick={() => mapZoomOut()}>
              <span></span>
            </div>
          </div>

          <div className={styles.mapPattern}></div>
        </div>
      </ScrollContainer>

      <div className={styles.mapBackground}></div>
    </>
  )
}
export default Map2
