import React from "react"
//import PropTypes from "prop-types"
import { Link } from "gatsby"

import styles from "./mapNavigation.scss"

function MapNavigation() {
  return (
    <>
      <div className={"mapNavigation"}>
        <Link to="/vision/" className="square bg_orange"></Link>
        <Link to="/jiwon/" className="square bg_pink"></Link>
        <Link to="/collections/" className="square bg_purple"></Link>
        <Link to="/news/" className="square bg_blue"></Link>
        {/* <Link to="/shop/" className='square bg_green'></Link> */}
      </div>
      <div className={"mapZoom"}>
        <div className={"mapZoom_in square"}>
          <span></span>
          <span></span>
        </div>
        <div className={"mapZoom_out square"}>
          <span></span>
        </div>
      </div>
    </>
  )
}
export default MapNavigation
