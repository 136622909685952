import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeaderLogo from "../components/headerLogo/headerLogo"
import HeaderMenu from "../components/headerMenu/headerMenu"
import MapNavigation from "../components/mapNavigation/mapNavigation"
import Map from "../components/map/map"
import Map2 from "../components/map2/map2"

//import Image from "../components/image"

function PageIndex() {
  return (
    <Layout>
      <SEO title="Home" />

      <HeaderLogo />
      <HeaderMenu />

      {/*<MapNavigation></MapNavigation>*/}
      {/*<Map></Map>*/}
      <Map2></Map2>
    </Layout>
  )
}
export default PageIndex
