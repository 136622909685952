import PropTypes from "prop-types"
import React, { useState } from "react"

import styles from "./headerMenu.module.scss"

function HeaderMenu() {
  const [toggleState, setToggleState] = useState(styles.off)

  function toggle() {
    setToggleState(toggleState === styles.off ? styles.on : styles.off)
  }

  return (
    <>
      <div className={styles.header_menu}>
        <div className={`switch ${toggleState}`} onClick={toggle}>
          <div className={styles.btn_menu}>
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className={styles.menuDesktop}>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/ji.won.choi/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a href="mailto:info@sufamilan.com" target="_blank">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.menuModal}>
            <div
              className={"flex middle center ta-c"}
              style={{ height: "100%" }}
            >
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/ji.won.choi/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="mailto:info@sufamilan.com" target="_blank">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
HeaderMenu.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderMenu.defaultProps = {
  siteTitle: ``,
}
export default HeaderMenu
