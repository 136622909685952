import React from "react"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./map.module.scss"

//import Image from "../../components/image"
import SVGMap from "../../assets/JWC_MAP_04.svg"
import MapElementLink from "../../components/mapElementLink/mapElementLink"
import MapElementLinkImage01 from "../../images/JWC_item-01_00.png"
import MapElementLinkImage02 from "../../images/JWC_item-02_00.png"
import MapElementLinkImage03 from "../../images/JWC_item-03_00.png"
import MapElementLinkImage04 from "../../images/JWC_item-04_00.png"
import MapElementGif01 from "../../images/JWC_item-08_00.gif"
import MapElementGif02 from "../../images/JWC_item-09_00.gif"
import MapElementGif03 from "../../images/JWC_item-10_00.gif"

function Map() {
  return (
    <>
      <div className={styles.map}>
        <SVGMap />

        <img id={styles.mapElementGif01} src={MapElementGif01} alt="" />
        <img id={styles.mapElementGif02} src={MapElementGif02} alt="" />
        <img id={styles.mapElementGif03} src={MapElementGif03} alt="" />

        <MapElementLink
          id={"mapElementVision"}
          title={"Vision"}
          href={"/vision/"}
          image={MapElementLinkImage01}
        />

        <MapElementLink
          id={"mapElementJiwon"}
          title={"Jiwon"}
          href={"/jiwon/"}
          image={MapElementLinkImage02}
        />

        <MapElementLink
          id={"mapElementCollections"}
          title={"Collections"}
          href={"/collections/"}
          image={MapElementLinkImage03}
        />

        <MapElementLink
          id={"mapElementNews"}
          title={"News"}
          href={"/news/"}
          image={MapElementLinkImage04}
        />

        <div className={styles.mapPattern}></div>
      </div>

      <div className={styles.mapBackground}></div>
    </>
  )
}
export default Map
